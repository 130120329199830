.panel-doc-type {
    display: flex;
    border: 1px solid;
    border-radius: 5px;
    align-items: center;
    border-color: rgba(220, 225, 227, 1);
    background-color: rgba(248, 249, 249, 1);
    text-decoration: none;
    margin-bottom: 15px;
    cursor: pointer;
}

.panel-doc-type:first-child {
    margin-top: 30px;
}

.panel-doc-type-flag {
    display: flex;
    border: 1px solid;
    border-radius: 5px;
    align-items: center;
    border-color: rgba(220, 225, 227, 1);
    background-color: rgba(248, 249, 249, 1);
    text-decoration: none;
    margin-bottom: 15px;
    cursor: pointer;
}

.panel-doc-type-flag .round-icon-blue {
    background-color: white;
    padding: 8px;
}

.panel-doc-type h2 {
    color: #364045;
    /* font-size: 14px; */
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 4px;
}

.panel-doc-type p {
    color: #a8b4ba;
    /* font-size: 14px; */
    line-height: 16px;
    margin-top: 4px;
}

.chevron {
    float: right;
    margin-top: 22px;
}

.change {
    font-weight: bold;
}

.country {
    color: #364045;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
}

.flag {
    color: #364045;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
}

.button-back {
    height: 33px;
    max-width: 85px;
    background: #EEEFF3;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    border: none;
    font-weight: 600;
}


/* .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    stroke-width: 0;
} */