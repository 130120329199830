.balance-image-logo {
    width: 80%;
}

.balance-image-user {
    width: 50%;
    height: inherit;
}

@media (max-width: 600px) {
    .balance-image-user {
        width: 60%;
    }
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: inherit;
    background-color: white;
    color: white;
    text-align: center;
    align-items: center;
}

.page-capture {
    position: fixed !important;
    height: 100% !important;
    width: 100% !important;
    background-color: gray !important;
}