.btn {
    height: 44px;
    width: 70%;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-box-shadow: inset 0 1px 0 1px rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(54, 64, 69, 0.1);
    -webkit-box-shadow: inset 0 1px 0 1px rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(54, 64, 69, 0.1);
    box-shadow: inset 0 1px 0 1px rgba(255, 255, 255, 0.1), 0 1px 0 0 rgba(54, 64, 69, 0.1);
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    text-align: center;
    margin-top: 24px;
    color: white;
    cursor: pointer;
}


/* @media (min-width: 678px) {
    .btn {
        margin-left: 10%;
    }
} */

@media (min-width: 576px) {
    .btn {
        font-weight: bold;
        font-size: 14px;
        width: 35%;
    }
}

.btn.blue {
    border: 1px solid rgb(0, 151, 221);
    background-color: rgb(0, 164, 245);
}

.btn.green {
    border: 1px solid rgb(13, 128, 70);
    background-color: rgb(74, 179, 54);
}

.btn.white {
    border: 1px solid rgb(187, 197, 201);
    background: linear-gradient( 180deg, rgb(255, 255, 255) 0%, rgb(248, 249, 249) 100%);
    color: rgb(56, 64, 72);
    box-shadow: 0 1px 0 0 rgba(54, 64, 69, 0.05);
}

.btn.gray {
    border: 0;
    background-color: #ececec;
    color: #c6c4c4;
}