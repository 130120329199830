.ui-canvas-container {
    z-index: -1;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
}

.document-passport {
    position: absolute;
    z-index: 1;
    margin: -20px auto;
    border: 5px solid white;
    align-self: center;
    width: 85%;
    flex-shrink: 0;
}

.document-other {
    position: absolute;
    z-index: 1;
    margin: 0 auto;
    border: 5px solid white;
    align-self: center;
    width: 85%;
    flex-shrink: 0;
}

.document-idcard {
    position: absolute;
    z-index: 1;
    margin: -20px auto;
    border: 5px solid white;
    align-self: center;
    width: 85%;
    flex-shrink: 0;
}

@keyframes htmlRatioComponentNodeInserted {
    from {
        color: currentColor;
    }
    to {
        color: currentColor;
    }
}

*[ratio] {
    -webkit-animation-duration: .001s;
    animation-duration: .001s;
    -webkit-animation-name: htmlRatioComponentNodeInserted;
    animation-name: htmlRatioComponentNodeInserted;
}