.slow-network-warning {
  background-color: lightgoldenrodyellow;
  position: absolute;
  left: 50%;
  top: 5px;
  transform: translateX(-50%);
  border-radius: 3px;
  text-align: center;
  margin: auto;
  margin-top: 2px;
  width: 350px;

  white-space: nowrap;
  font-size: 12px;
  line-height: 2em;
  z-index: 300;
}

.slow-network-warning .x {
  cursor: pointer;
  position: fixed;
  padding-left: 10px;
  right: 3px;
}
