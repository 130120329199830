.capture-button-panel button,
.capture-button-panel div {
    display: block;
    width: 5em;
    max-width: 150px;
    font-size: 1em;
    padding: 0;
}

.capture-button-panel .btn.blue {
    border: 2px solid white;
}

.capture-button-panel .btn {
    border-radius: 50%;
    height: 3em;
    width: 3em;
    max-height: 150px;
}

.fontCheck {
    font-size: 14px;
    color: white;
    text-align: center;
}

.capture-button-panel .btn.gray {
    border: 2px solid rgba(10, 10, 10, 0.2);
    background-color: rgba(10, 10, 10, 0.2);
}

.main-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-1 {
    position: absolute;
    margin-bottom: 5vh;
    margin-top: 1EM;
    left: 6%;
}

.flip-camera {
    /* border-radius: 50%; */
    /* height: 4em;
    width: 4em; */
    max-height: 140px;
    border: 0px;
    /* margin-top: 10%; */
    margin-bottom: 0px;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.box-3 {
    margin-bottom: 5vh;
    margin-top: 1em;
    position: absolute;
    right: 7%;
}

.flip-camera img {
    height: 32px;
    width: 32px;
}