.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  stroke-width: 0;
}

.icon-lg {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}

.icon-sm {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.icon-gray {
  fill: #5c6e77;
}

.icon-white {
  fill: #ffffff;
}
