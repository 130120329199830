.instructions-on-face {
    position: absolute;
    margin: 0;
    text-align: center;
    font-size: 5.5vh;
    font-weight: bold;
    color: white;
    top: 50%;
    left: 50%;
    /* -webkit-text-stroke: 1px black; */
    /* text-shadow: 0 0 10px green; */
    transform: translate(-50%, -50%);
    line-height: 1em;
}

.instructions-on-face-countdown {
    position: absolute;
    margin: 0;
    text-align: center;
    font-size: 6vh;
    font-weight: bold;
    color: white;
    top: 50%;
    left: 50%;
    /* -webkit-text-stroke: 1px black; */
    /* text-shadow: 0 0 10px green; */
    transform: translate(-50%, -50%);
}