.instructions-on-face {
  position: absolute;
  margin: 0;
  text-align: center;
  font-size: 5.5vh;
  font-weight: bold;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1em;
}

.instructions-on-face-countdown {
  position: absolute;
  margin: 0;
  text-align: center;
  font-size: 7vh;
  font-weight: bold;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
