@media (min-aspect-ratio: 3/4) {
    .ui-canvas-container {
        height: 100%;
        width: calc(100vh * 3/4);
        margin: 0 auto;
    }
    .overlay-oval {
        mask-image: radial-gradient( 57% 57% ellipse at 50% 46%, transparent 50%, white 53%);
        -webkit-mask-image: radial-gradient( 57% 57% ellipse at 50% 46%, transparent 50%, white 53%);
        mask-size: cover;
        -webkit-mask-size: cover;
    }
    footer {
        width: calc(95vh * 3 / 4);
        margin: 0 auto;
    }
}