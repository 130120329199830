.main-content {
    border-style: solid;
    border-radius: 2em;
    border-color: brown;
}

button {
    font-family: 'Chakra Petch', sans-serif;
}

.button-hov {
    border-color: #D31145;
    border: 1px solid #333D47;
}

.button-hov:hover {
    background-color: #D31145;
    border: 1px solid #333D47;
}

.button-callback-hover {
    background-color: #ffffff;
    border: 2px solid #4CAF50;
    color: #4CAF50;
}

.button-callback-hover:hover {
    background-color: #4CAF50;
    border: 2px solid #4CAF50;
    color: #ffffff;
}

.tag-status {
    height: 33px;
    max-width: 70px;
    background: #EEEFF3;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1.6fr 1fr;
    align-items: center;
    padding: 0px 10px;
    margin-inline-start: auto;
    /* cursor: pointer; */
    border: none;
    /* font-weight: 600; */
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: inherit;
    background-color: white;
    color: white;
    text-align: center;
    align-items: center;
}

.custom-dialog {
    width: 100vw;
}

.scroll-bar-intro {
    scroll-behavior: smooth;
    max-height: 60vh;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}


/* .scroll-bar-intro::-webkit-scrollbar {
    width: 5px;
}

.scroll-bar-intro::-webkit-scrollbar-track {
    background: orange;
}

.scroll-bar-intro::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
} */