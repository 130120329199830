html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px;
    /* Margin bottom by footer height */
}


/* ::placeholder {
    color: red;
    opacity: 1;
}

 :-ms-input-placeholder {
    color: red;
}

 ::-ms-input-placeholder {
    color: red;
} */

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: inherit;
    background-color: white;
    color: white;
    text-align: center;
    align-items: center;
}

.block {
    text-align: justify;
    text-align-last: justify;
    width: 100%;
    height: 1.1em;
    line-height: 1.1;
}

.block:after {
    content: ".";
    display: inline-block;
    width: 100%;
    height: 0;
    visibility: hidden
}

.start {
    float: left;
}

.end {
    float: right;
}

.margin-n1 {
    background-color: #D31145;
    height: 40;
    text-align: 'center';
    padding: 0;
    margin: 0;
}

.header {
    display: flex;
    justify-content: center;
}

.__react_modal_image__icon_menu a {
    pointer-events: all;
}

.daon-image {
    align-self: center;
    width: 50%;
    height: 50%;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    /* Set the fixed height of the footer here */
    line-height: 60px;
    /* Vertically center the text there */
    background-color: #f5f5f5;
}

.checkbox {
    -ms-transform: scale(2);
    /* IE */
    -moz-transform: scale(2);
    /* FF */
    -webkit-transform: scale(2);
    /* Safari and Chrome */
    -o-transform: scale(2);
    /* Opera */
    transform: scale(2);
    margin-right: 40px;
}

#daon_image {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.container {
    width: auto;
    max-width: 680px;
    padding: 0 15px;
}

.container_sectionHeader {
    width: auto;
    max-width: 680px;
    padding: 15px 15px;
}


/* Custom page CSS
  -------------------------------------------------- */


/* Not required for template or sticky footer method. */

.centered-form .panel {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: rgba(0, 0, 0, 0.3) 20px 20px 20px;
}

.card-header {
    background-color: black;
}

.sr-only {
    font-size: 0px;
}

.label-font-size {
    font-size: 1.5em;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal-dialog-scrollable {
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-header .close {
    padding: 0.7rem 0.7rem !important;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.modal-footer> :not(:first-child) {
    margin-left: 0.25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.custom-panel {
    margin-top: 0;
    margin-bottom: -10%;
}

#ocrData {
    text-align: center;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    border: 3px solid #ddd;
    width: 100%;
}

#ocrData td,
#students th {
    border: 1px solid #ddd;
    padding: 8px;
}

#ocrData tr:nth-child(even) {
    background-color: #f2f2f2;
}

#ocrData tr:hover {
    background-color: #ddd;
}

#ocrData th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #d97d1b;
    color: white;
}