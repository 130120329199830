.light-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #D3CAC3;
    transition: width 0.6s ease;
    z-index: 200;
    margin-left: 2px;
    margin-right: 2px;
}

.empty-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #f3f4f4;
    transition: width 0.6s ease;
    z-index: 200;
    margin-left: 2px;
    margin-right: 2px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #22A8DA;
    transition: width 0.6s ease;
    z-index: 200;
    margin-left: 2px;
    margin-right: 2px;
}

.mainBar {
    background-color: white;
}