.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: inherit;
    background-color: white;
    color: white;
    text-align: center;
    align-items: center;
}

.font-text {
    font-family: 'AIA Regular';
    /* font-size: 0.9em; */
    color: black;
}