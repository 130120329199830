.wrapper {
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
}

a {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}


/* canvas {
  width: auto;
  max-height: 100%;
  z-index: -1;
  position: relative;
} */

canvas {
    width: auto;
    height: 100%;
}