.box-size {
    max-width: 18rem;
    max-height: 18rem;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: inherit;
    background-color: white;
    color: white;
    text-align: center;
    align-items: center;
}

.camera-capture {
    width: 10%;
    cursor: pointer;
}

.disable-second-image {
    pointer-events: none;
    opacity: 0.2;
}

.margin-n2 {
    background-color: #D31145;
    height: 55px;
}

.button-capture-second {
    border-radius: 5px;
    background-color: #FF754D;
    color: white;
}

.modal-image-second {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content-second {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}


/* 100% Image Width on Smaller Screens */

@media only screen and (max-width: 700px) {
    .modal-content-second {
        width: 100%;
    }
}