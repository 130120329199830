.instructions-on-portrait {
    position: relative;
    display: block;
    font-size: 6vh;
    margin-left: auto;
    margin-top: 5vh;
    color: #fff;
    text-align: center;
}


/* .instructions-on-portrait-header {
    position: relative;
    display: block;
    font-size: 1.7rem;
    margin-left: auto;
    margin-top: 3vh;
    color: #1F78AD;
    text-align: center;
} */

.instructions-on-portrait-title {
    position: relative;
    display: block;
    font-size: 1.6rem;
    margin-left: auto;
    margin-top: 3vh;
    color: #fff;
    text-align: center;
}

._loading_overlay_overlay {
    background: rgba(0, 0, 0, 0.8);
}

.message-box {
    margin-bottom: 2%;
}

@media (max-height: 1140px) {
    .message-box {
        margin-bottom: 2%;
    }
}

.no-scroll {
    touch-action: pan-y;
}