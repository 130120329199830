.button-link {
    color: rgb(0, 0, 0);
    margin-left: 10px;
    background: none;
    border: none;
    font: inherit;
    font-size: 0.8em;
    font-family: AIA Regular;
    /* text-decoration: underline; */
    cursor: pointer;
}

.button-link:hover {
    color: rgb(16, 103, 216);
    text-decoration: underline;
    font-size: 1em;
    /* transition-delay: 200ms; */
    transition-duration: 100ms;
}