.instructions-on-portrait {
    position: relative;
    display: block;
    font-size: 4vh;
    margin-left: auto;
    margin-top: 2vh;
    color: #fff;
    text-align: center;
    /* width: 50%; */
    /* max-width: 70vh; */
}

.instructions-on-portrait-header {
    position: relative;
    display: block;
    font-size: 1.7rem;
    margin-left: auto;
    margin-top: 3vh;
    color: #fff;
    text-align: center;
}