.native-capture-box {
  background-color: lightgoldenrodyellow;
  position: relative;
  border-radius: 3px;
  text-align: center;
  margin: auto;
  margin-top: -9.5vh;
  width: 350px;
  white-space: nowrap;
  font-size: 12px;
}

.native-capture-box button {
  color: rgb(0, 151, 221);
}

.native-capture-box .x {
  position: absolute;
  margin-top: 0.5vh;
  padding-left: 10px;
  cursor: pointer;
  right: 3px;
}
