h1 {
    color: rgb(54, 64, 69);
    margin: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
}

p {
    color: rgb(0, 0, 0);
    font-style: normal;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.error {
    color: #e24949;
}

label {
    color: rgb(54, 64, 69);
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    display: block;
}

input {
    font-size: inherit;
}

.verification {
    text-align: center;
}