.capture-header {
    color: black;
    position: fixed;
    text-align: center;
    left: 0;
    right: 0;
    margin-top: 10%;
    opacity: 60%;
}

.instructions-on-liveness-header {
    position: relative;
    display: block;
    font-size: 4vh;
    /* margin-left: auto; */
    /* font-weight: bold; */
    margin: auto;
    font-family: 'AIA Regular';
    /* margin-top: 2vh; */
    /* color: #1F78AD; */
    color: #fff;
    text-align: center;
    max-width: 70vh;
    /* width: 50%; */
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: inherit;
    background-color: white;
    color: white;
    text-align: center;
    align-items: center;
}