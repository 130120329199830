/* html {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
}
  
@supports (font-variation-settings: normal) {
    html {
      font-family: 'IBM Plex Sans', sans-serif;
    }
} */

h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    color: #002846;
    margin-bottom: 1rem;
}

h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    color: #002846;
    margin-bottom: 1rem;
}

h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    color: #002846;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 667px) {
    h1 {
        font-style: normal;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    h2 {
        font-style: normal;
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }
    h3 {
        font-style: normal;
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    h4 {
        font-style: normal;
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }
    h5 {
        font-style: normal;
        font-size: 0.7rem;
        margin-bottom: 1rem;
    }
    h6 {
        font-style: normal;
        font-size: 0.6rem;
        margin-bottom: 1rem;
    }
    a {
        font-style: normal;
        font-size: 0.7rem;
        margin-bottom: 1rem;
    }
    p {
        font-style: normal;
        font-size: 0.7rem;
        margin-bottom: 1rem;
    }
}


/* button {
    font-family: 'IBM Plex Sans';
    font-weight: 600;
} */