._loading_overlay_wrapper {
    height: 100%;
}


/* @media only screen and (orientation:landscape) {
    body {
        height: 100vw;
        transform: rotate(-90deg);
    }
} */

.title-text {
    font-family: 'AIA Regular';
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: inherit;
    background-color: white;
    color: white;
    text-align: center;
}

._loading_overlay_overlay {
    position: absolute;
    height: 100% !important;
}

.page-capture {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: gray;
}

.page-standard {
    height: 100vh !important;
}


/* @media only screen and (orientation: landscape) {
    .page-capture {
        transform: rotate(-90deg);
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        position: absolute;
    }
} */

.build-number {
    text-align: right;
    margin: 10px;
    color: rgb(56, 64, 72);
    font-size: 12px;
    line-height: 15px;
}

.side {
    display: none;
}


/* .main-content {
    display: flex;
    justify-content: center;
    align-self: center;
} */

@media (min-width: 724px) {
    .side {
        width: 25%;
        min-width: 250px;
        display: block;
    }
    .wrap {
        display: flex;
        min-height: 100vh;
    }
    .main-content {
        display: flex;
        justify-content: center;
        align-self: center;
    }
}