html,
body,
#root {
    height: 100%;
}


/* body {
    margin: 0;
    padding: 0;
}

header {
    min-height: 36px;
}

a {
    color: rgb(0, 151, 221);
} */

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
}

footer[class="start-page"] {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
}

hr {
    height: 1px;
    color: rgb(191, 230, 184);
    background-color: rgb(191, 230, 184);
    border: none;
    margin: 0;
}

input[type="number"] {
    height: 44px;
    padding: 0;
    width: 100%;
    border: 0;
    border-radius: 3px;
    background-color: white;
    box-shadow: inset 0 0 0 1px rgba(92, 110, 119, 0.25), inset 0 1px 2px 0 rgba(92, 110, 119, 0.2);
    -moz-box-shadow: inset 0 0 0 1px rgba(92, 110, 119, 0.25), inset 0 1px 2px 0 rgba(92, 110, 119, 0.2);
    -webkit-box-shadow: inset 0 0 0 1px rgba(92, 110, 119, 0.25), inset 0 1px 2px 0 rgba(92, 110, 119, 0.2);
    -webkit-appearance: none;
}

input[type="file"] {
    display: none;
}

.center {
    text-align: center;
}

.column {
    flex-flow: row wrap;
    flex: 1 100%;
    align-items: center;
    flex-direction: column;
    flex-basis: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 71px;
}

.row-justify-space {
    justify-content: space-between;
}

.row-justify-center {
    justify-content: center;
}

.row-justify-space button {
    width: 48%;
}

.row svg {
    margin: 15px;
}

@media only screen and (min-height: 1400px) {
    .img-preview {
        width: 80%;
        display: block;
        margin: 0 auto;
    }
}

@media only screen and (max-height: 1400px) {
    .img-preview {
        width: 60%;
        display: block;
        margin: 0 auto;
    }
}

.ui-canvas-container {
    z-index: -1;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
}

.calculating-canvas {
    position: absolute;
    z-index: 1;
    opacity: .8;
}

.instruction_gif {
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 0;
    display: flex;
    width: 350px;
    height: 350px;
}

.flip-x {
    transform: scaleX(-1) !important;
}

.f3d-animation {
    z-index: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
}

.ui-canvas {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.progress {
    display: flex;
    height: 1.5rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    z-index: 200;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
    z-index: 200;
}

.progress-bar-striped {
    background-image: linear-gradient( 45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.bg-success {
    background-color: blue !important;
}

.bg-warning {
    background-color: grey !important;
}

.take-a-selfie {
    position: relative;
    height: 32px;
    border-radius: 3px;
    color: #f8f9f9;
    font-size: 3.5vh;
    font-weight: 600;
    white-space: nowrap;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: auto;
    margin-top: 10vh;
}

.landscape-safari {
    height: max-content;
    width: auto;
    z-index: -1;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
}

.others-landscape {
    width: 100%;
    height: auto;
    z-index: -1;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
}

.overlay {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(92, 110, 119, 0.6);
}

@media screen and (orientation: landscape) {
    .overlay-rect {
        -webkit-mask-size: 100vw 83vh;
        mask-size: 100vw 83vh;
    }
}

@media screen and (orientation: portrait),
(min-width: 992px) {
    .overlay-rect {
        -webkit-mask-size: cover;
        mask-size: cover;
    }
}

.overlay-oval {
    mask-image: radial-gradient( 70% 55% ellipse at 50% 46%, transparent 50%, white 53%);
    -webkit-mask-image: radial-gradient( 70% 55% ellipse at 50% 46%, transparent 50%, white 53%);
    mask-size: cover;
    -webkit-mask-size: cover;
}

.displaycanvas {
    border: 1px solid black;
    /* z-index: 5; */
    position: absolute;
    top: 0px;
    left: 0px;
    width: var(--cam-width);
    height: var(--cam-height);
    pointer-events: none;
    transform: rotateY(180deg);
    /* preview mirror */
}

.w-full {
    width: 100%;
}

.m-20 {
    margin: 1px;
}

.m-15 {
    margin: 15px;
}

.box-shadow {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-appearance: none;
    -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

.message-box {
    width: 100%;
    border-radius: 3px;
    background-color: white;
    color: rgba(54, 64, 69, 1);
    font-size: 2.2vh;
    line-height: 20px;
    text-align: center;
}

.message-box-error {
    color: rgb(219, 49, 6);
}

.scale-edge {
    transform: scale(-2, 2.4) !important;
}

.hiddenEdge {
    overflow: hidden;
}